import React from "react";
import { Redirect } from "react-router-dom";


// Profile
import UserProfile from "../pages/Authentication/user-profile";



// //Tasks
import TasksKanban from "../pages/Tasks/tasks-kanban";
import MyTasksKanban from "../pages/Tasks/my-tasks-kanban";

//Email
import EmailInbox from "../pages/Email/email-inbox";



// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import NoAuth from "../pages/Authentication/NoAuth";
import Register from "../pages/Authentication/Register";
import ForgotPwd from "../pages/Authentication/ForgotPassword";

//Companies
import CompaniesList from "../pages/Companies/companies-list";
import CompaniesProfile from "../pages/Companies/companies-profile";

//Contacts
import ContactsList from "../pages/Contacts/contacts-list";
import ContactDetails from "../pages/Contacts/ContactDetails";
import ChangePassword from "../pages/Authentication/change-password";

//Properties
import WebsitesList from "../pages/Properties/websites-list";
import WebsiteDetails from "../pages/Properties/website-details";

//Team
import TeamsList from "../pages/Team/teams-list";

//Domain
import DomainsList from "../pages/Domains/domains-list";

// Settings
import Settings from "../pages/Settings";

import {
    getCookie
} from '../Utils/helper';
import logsList from "../pages/Logs/logs-list";

let access_token = getCookie("access_token");

const userRoutes = access_token == '' ? [] : [

	// //profile
	{ path: "/profile", component: UserProfile },
	{ path: "/change-password", component: ChangePassword },

	//Email
	{ path: "/email-inbox", component: EmailInbox },


	// Tasks
	{ path: "/tasks-kanban", component: TasksKanban },
	{ path: "/my-requests-kanban", component: MyTasksKanban },


	// Designs
	{ path: "/design-kanban", component: TasksKanban },

	// Sales
	{ path: "/sales-kanban", component: TasksKanban },
    
    // Companies
	{ path: "/companies-list", component: CompaniesList },
	{ path: "/companies-profile", component: CompaniesProfile },

	// Properties
	{ path: "/websites-list", component: WebsitesList },
	{ path: "/website-details", component: WebsiteDetails },

	// Teams
	{ path: "/teams-list", component: TeamsList },

	// Domain
	{ path: "/domains-list", component: DomainsList },

	// Settings
	{ path: "/settings", component: Settings },

	// Logs
	{ path: "/logs-list", component: logsList },

	// Contacts
	{ path: "/contacts-list", component: ContactsList },
	//{ path: "/contacts-profile", component: ContactsProfile },
	{ path: "/contacts-profile", component: ContactDetails },
	
    // this route should be at the end of all other routes
    // { path: "/", exact: false, component: () => <Redirect to="/email-inbox" /> },
    { path : "/*", component: NoAuth },
];

const authRoutes = access_token == '' ?
[
	{ path: "/login", component: Login },
	{ path: "/reset-password", component: Login },
	{ path: "/forgot-password", component: ForgotPwd },
	{ path: "/register", component: Register },
  { path : "/*", component: NoAuth },
]
:
[
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
    // { path: "/forgot-password", component: ForgetPwd },
    // { path : "/auth-lock-screen", component: LockScreen },
];

export { userRoutes, authRoutes };
