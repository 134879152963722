import React, { useEffect, useState } from 'react';
import Api from "../../Utils/API";
import Select from "react-select";
import { customStyles, urlValidator } from '../../Utils/helper';
import { handleErrors } from '../../Utils/handleError';
import Swal from 'sweetalert2';
import TeamAssignmentRow from '../../components/PropertyManagement/TeamAssignmentRow';
import TextareaAutosize from 'react-textarea-autosize';

const DEFAULT_CREDENTIAL = [
    {
        type: 'Domain Host',
        details: {
            hostName: 'GoDaddy',
            inLastPass: false
        }
    },
    {
        type: 'DNS Host',
        details: {
            hostName: 'Cloudflare',
            inLastPass: false
        }
    },
    {
        type: 'Website Host',
        details: {
            hostName: 'Loop Hosting - Siteground',
            inLastPass: false
        }
    },
]

export const WebsiteForm = React.memo(props => {
    const [state, setState] = React.useState({
        company_id: props.websiteDetails.company_id,
        selectedCompany: '',
        selectedContact: '',
        ismoreCredential: false,
        isSubmit: false,
        description: '',
        // moreDetails: [],
        url: props.websiteDetails.name,
        primary_contact: [],
        username: '',
        password: '',
        credentials: DEFAULT_CREDENTIAL,
        // defaultCredentials: [
        //     {
        //         name: 'Web Host',
        //         details: {
        //             username: '',
        //             password: '',
        //             url: ''
        //         }
        //     },
        //     {
        //         name: 'Website Admin',
        //         details: {
        //             username: '',
        //             password: '',
        //             url: ''
        //         }
        //     },
        //     {
        //         name: 'SFTP',
        //         details: {
        //             username: '',
        //             url: '',
        //             password: '',
        //             port: '' 
        //         }
        //     },
        // ],
        credentials_value: '',
        contacts: props.contacts,
        companies: props.companies || [],
        // companyOptions: [],
        pricingMethods: [],
        projectManagers: [],
        developers: [],
        qas: [],
        designers: [],
        pricing: null,
        deploymentPolicy: "",
        siteNotes: ""
    });
    // const [deploymentPolicy, setDeploymentPolicy] = useState("");
    // const [siteNotes, setSiteNotes] = useState("");

    const fetchWebsiteNotes = () => {
        Api.getWebsiteNotes(props.id)
        .then(({data}) => {
            data.map(item => {
                if(item.type === "deployment_policy") {
                    setState((prevState) => ({
                        ...prevState,
                        deploymentPolicy: item.notes
                    }));
                }

                if(item.type === "site_notes") {
                    setState((prevState) => ({
                        ...prevState,
                        siteNotes: item.notes
                    }));
                }
            });
        })
        .catch((error) => {
            console.log("::fetchWebsiteNotes > error", error);
        });
    }

    useEffect(() => {
        if(props.websiteDetails) {
            const details = props.websiteDetails;
            let creds = details.details;

            setState((prevState) => ({
                ...prevState,
                company_id: details.company_id,
                companyOptions: props.companyOptions,
                selectedCompany: props.companyOptions.filter((item) => item.value == details.company_id)?.[0],
                url: details.name,
            }));



            if(creds != null) {
                creds = JSON.parse(creds).credentials;
                if(creds!=null){
                    setState((prevState) => ({
                        ...prevState,
                        credentials: creds
                    }));
                }
                else {
                    setState((prevState) => ({
                        ...prevState,
                        credentials: DEFAULT_CREDENTIAL
                    }));
                }
            }
            else {
                setState((prevState) => ({
                    ...prevState,
                    credentials: DEFAULT_CREDENTIAL
                }));
            }

            fetchWebsiteNotes();
            getPricingMethodOptions();
        }

        return () => {}
    }, [props.websiteDetails, props.companyOptions]);

    useEffect(() => {
        const details = props.websiteDetails;

        Api.getCompanyContacts(state.selectedCompany?.value ?? 0).then(result => {
            setState((prevState) => ({
                ...prevState,
                contactOptions: result.data,
                selectedContact: result.data.filter((item) => item.value == details.primary_contact_id)?.[0],
            }));
        }).catch(error => {
            console.log(error);
            handleErrors();
        });

        return () => {}
    }, [state.selectedCompany]);

    useEffect(() => {
        const assignees = props.websiteDetails?.assignees ?? [];
        const projectManagers = [];
        const designers = [];
        const developers = [];
        const qas = [];

        if(assignees.length) {
            assignees.map((assignee) => {
                switch (assignee.type) {
                    case "project manager":
                        return projectManagers.push(assignee.user.id);
                    case "designer":
                        return designers.push(assignee.user.id);
                    case "qa":
                        return qas.push(assignee.user.id);
                    default:
                        return developers.push(assignee.user.id);
                }
            });

            setState((prevState) => ({
                ...prevState,
                projectManagers,
                designers,
                developers,
                qas
            }));
        }

        return () => {}
    }, [props.websiteDetails?.assignees]);

    function getPricingMethodOptions() {
        Api.getPricingMethodOptions().then(result => {
            setState((prevState) => ({
                ...prevState,
                pricingMethods: result.data,
                pricing: result.data.filter((item) => item.value == props.websiteDetails.pricing_method_id)?.[0],
            }));
        }).catch(error => {
            console.log(error);
            handleErrors();
        });
    }

    // function updateCredentials(index, data, subdata, _value) {
    //     let temp = state.credentials;

    //     if (subdata != null) {
    //         if(subdata === 'port') {
    //             _value = _value.toString().replace('e', '');
    //             _value = _value.replace('-', '');
    //             _value = parseInt(_value);
    //         }

    //         temp[index][data][subdata] = _value;
    //     } else {
    //         temp[index][data] = _value;
    //     }

    //     setState((prevState) => ({ ...prevState, temp }));
    // }

    function submitWebsite(event) {
        event.preventDefault();
        // const { credentials } = state;

        // validate url using urlValidator(string: url, boolean(optional, defaults to false): isRequired)
        let urlError = urlValidator(state.url, true);

        // validate urls and ports for the website credentials (Website Admin, Web Host, SFTP)
        let hasErrors = false;
        // for (let i = 0; i < credentials.length; i++) {
        //     if(credentials[i].details.url) {
        //         credentials[i].details.urlError = urlValidator(credentials[i].details.url);
        //         if(credentials[i].details.urlError) {
        //             hasErrors = true;
        //         }
        //     }

        //     if(credentials[i].details.port) {
        //         credentials[i].details.portError = numberValidator(credentials[i].details.port);
        //         if(credentials[i].details.portError) {
        //             hasErrors = true;
        //         }
        //     }
        // }

        // check for url errors and display an alert message
        if(urlError || hasErrors) {
            // setState({ ...state, isSubmit: false, urlError, credentials });
            setState({ ...state, isSubmit: false, urlError });
            return Swal.fire('Error', 'Please enter valid input!', 'error')
        }

        // validate company attribute
        if(state.companies.length > 0 && !state.selectedCompany) {
            setState({ ...state, isSubmit: false });
            return Swal.fire('Ooops!', 'Please select a company!', 'error')
        }

        // initialise params
        const params = {
            id: props.websiteDetails.id,
            name: state.url,
            company_id: state.companies.length > 0 ? state.selectedCompany.value : state.company_id,
            primary_contact_id: state.selectedContact?.value,
            type: 'website',
            details: {
                credentials: state.credentials,
            },
            pricing_id: state.pricing?.value,
            project_managers: state.projectManagers,
            developers: state.developers,
            qas: state.qas,
            designers: state.designers,
            deployment_policy: state.deploymentPolicy,
            site_notes: state.siteNotes,
        }

        setState({ ...state, isSubmit: true });
        Api.updateCompanyProperties(params).then(result => {
            // display success message -> toast
            // props.setToastData({
            //     title:'Success!',
            //     description: 'You successfully updated the website details!'
            // });
            props.showToast('Success!', 'You successfully updated the website details!');
        }).catch(error => {
            console.log(error);
            handleErrors();
        }).finally(() => {
            setState({ ...state, isSubmit: false });
        });
    }

    // const moreDetails = (e) => {
    //     let type = e.target.getAttribute("type");
    //     let values = [...state.credentials];
    //     if (type === '1') {
    //         values.push({
    //             type: type,
    //             name: 'Website Admin',
    //             details: {
    //                 username: '',
    //                 password: '',
    //                 url: ''
    //             }
    //         });
    //     }
    //     if (type === '2') {
    //         values.push({
    //             type: type,
    //             name: 'Web Host',
    //             details: {
    //                 username: '',
    //                 password: '',
    //                 url: ''
    //             }
    //         });
    //     }

    //     if (type === '3') {
    //         values.push({
    //             type: type,
    //             name: 'SFTP',
    //             details: {
    //                 username: '',
    //                 password: '',
    //                 url: '',
    //                 port: ''
    //             }
    //         });
    //     }
        
    //     if (type === '4') {
    //         values.push({
    //             type: type,
    //             name: '',
    //             details: {
    //                 username: '',
    //                 password: '',
    //                 url: '',
    //                 port: ''
    //             }
    //         });
    //     }

    //     setState((prevState) => ({ ...prevState, credentials: values, ismoreCredential: false }));
    // }

    // function handleChange(event) {
    //     let type = event.target.getAttribute('type');
    //     let values = [...state.credentials];
    //     const attribute = event.target.getAttribute('name');

    //     values[event.target.getAttribute('counter')].details[attribute] = event.target.value;
    //     if (type === '5') values[event.target.getAttribute('counter')][attribute] = event.target.value;

    //     setState((prevState) => ({ ...prevState, credentials: values }));
    // }

    // function editPort(event) {
    //     if(event.key == 'e' || event.key == '-' || event.key == '+') {
    //         event.preventDefault();
    //         return false;
    //     }
    // }

    const updateDesigners = (id, prev = null) => {
        const {designers} = state;
        let prevIndex = prev ? designers.indexOf(prev) : -1;

        if(designers.includes(id)) {
            // remove id from the array
            designers.splice(designers.indexOf(id), 1);
        } else {
            // check if prev is not null and if it is in the designers array
            if(prev && designers.includes(prev)) {
                // retrieve prev's index
                prevIndex = designers.indexOf(prev);
                // remove prev from the array
                designers.splice(prevIndex, 1, id);
            } else {
                designers.push(id);
            }
        }

        setState((prevState) => ({
            ...prevState,
            designers
        }));
    }

    const updateDevelopers = (id, prev = null) => {
        const {developers} = state;
        let prevIndex = prev ? developers.indexOf(prev) : -1;

        if(developers.includes(id)) {
            // remove id from the array
            developers.splice(developers.indexOf(id), 1);
        } else {
            // check if prev is not null and if it is in the developers array
            if(prev && developers.includes(prev)) {
                // retrieve prev's index
                prevIndex = developers.indexOf(prev);
                // remove prev from the array
                developers.splice(prevIndex, 1, id);
            } else {
                developers.push(id);
            }
        }

        setState((prevState) => ({
            ...prevState,
            developers
        }));
    }

    const updateProjectManagers = (id, prev = null) => {
        const {projectManagers} = state;
        let prevIndex = prev ? projectManagers.indexOf(prev) : -1;

        if(projectManagers.includes(id)) {
            // remove id from the array
            projectManagers.splice(projectManagers.indexOf(id), 1);
        } else {
            // check if prev is not null and if it is in the projectManagers array
            if(prev && projectManagers.includes(prev)) {
                // retrieve prev's index
                prevIndex = projectManagers.indexOf(prev);
                // remove prev from the array
                projectManagers.splice(prevIndex, 1, id);
            } else {
                projectManagers.push(id);
            }
        }

        setState((prevState) => ({
            ...prevState,
            projectManagers
        }));
    }

    const updateQas = (id, prev = null) => {
        const {qas} = state;
        let prevIndex = prev ? qas.indexOf(prev) : -1;

        if(qas.includes(id)) {
            // remove id from the array
            qas.splice(qas.indexOf(id), 1);
        } else {
            // check if prev is not null and if it is in the qas array
            if(prev && qas.includes(prev)) {
                // retrieve prev's index
                prevIndex = qas.indexOf(prev);
                // remove prev from the array
                qas.splice(prevIndex, 1, id);
            } else {
                qas.push(id);
            }
        }

        setState((prevState) => ({
            ...prevState,
            qas
        }));
    }

    const removeDesigner = (assignee) => {
        const {designers} = state;
        if(designers.includes(assignee.id)) {
            // remove id from the array
            designers.splice(designers.indexOf(assignee.id), 1);
        }

        setState((prevState) => ({
            ...prevState,
            designers
        }));
    }

    const removeDeveloper = (assignee) => {
        const {developers} = state;
        if(developers.includes(assignee.id)) {
            // remove id from the array
            developers.splice(developers.indexOf(assignee.id), 1);
        }

        setState((prevState) => ({
            ...prevState,
            developers
        }));
    }

    const removeProjectManager = (assignee) => {
        const {projectManagers} = state;
        if(projectManagers.includes(assignee.id)) {
            // remove id from the array
            projectManagers.splice(projectManagers.indexOf(assignee.id), 1);
        }

        setState((prevState) => ({
            ...prevState,
            projectManagers
        }));
    }

    const removeQa = (assignee) => {
        const {qas} = state;
        if(qas.includes(assignee.id)) {
            // remove id from the array
            qas.splice(qas.indexOf(assignee.id), 1);
        }

        setState((prevState) => ({
            ...prevState,
            qas
        }));
    }

    const selectPricing = (e) => {
        setState((prevState) => ({ ...prevState, pricing: e }));
    }

    const selectCompany = (e) => {
        setState((prevState) => ({
            ...prevState,
            selectedCompany: e
        }));
    }

    const selectContact = (e) => {
        setState((prevState) => ({ ...prevState, selectedContact: e }));
    }

    const updateCredentials = (index, e) => {
        const temp = state.credentials;
        // update the credential base on index
        // 0 for domain
        // 1 for dns
        // 2 for host
        temp[index].details.hostName =  e.target.value;

        setState((prevState) => ({
            ...prevState,
            credentials: temp
        }))
    }

    const updateDomainInLastpass = (e) => {
        const temp = state.credentials ?? [];

        temp[0].details.inLastPass =  e.target.checked;

        setState((prevState) => ({
            ...prevState,
            credentials: temp
        }))
    }

    const updateDnsInLastpass = (e) => {
        const temp = state.credentials ?? [];

        temp[1].details.inLastPass =  e.target.checked;

        setState((prevState) => ({
            ...prevState,
            credentials: temp
        }))
    }

    const updateWebsiteInLastpass = (e) => {
        const temp = state.credentials ?? [];

        temp[2].details.inLastPass =  e.target.checked;

        setState((prevState) => ({
            ...prevState,
            credentials: temp
        }))
    }

    const reset = () => {
        if(props.websiteDetails) {
            const details = props.websiteDetails;
            let creds = details.details;

            setState((prevState) => ({
                ...prevState,
                company_id: details.company_id,
                companyOptions: props.companyOptions,
                selectedCompany: props.companyOptions.filter((item) => item.value == details.company_id)?.[0],
                selectedContact: state.contactOptions.filter((item) => item.value == details.primary_contact_id)?.[0],
                url: details.name,
                pricing: state.pricingMethods.filter((item) => item.value == props.websiteDetails.pricing_method_id)?.[0],
                company_id: props.websiteDetails.company_id,
                ismoreCredential: false,
                isSubmit: false,
                url: props.websiteDetails.name,
            }));

            if(creds != null) {
                creds = JSON.parse(creds);
                setState((prevState) => ({
                    ...prevState,
                    credentials: JSON.parse(details.details).credentials
                }));
            }

            fetchWebsiteNotes();
        }

        /** RESET ASSIGNEES */
        const assignees = props.websiteDetails?.assignees ?? [];
        const projectManagers = [];
        const designers = [];
        const developers = [];
        const qas = [];

        if(assignees.length) {
            assignees.map((assignee) => {
                switch (assignee.type) {
                    case "project manager":
                        return projectManagers.push(assignee.user.id);
                    case "designer":
                        return designers.push(assignee.user.id);
                    case "qa":
                        return qas.push(assignee.user.id);
                    default:
                        return developers.push(assignee.user.id);
                }
            });

            setState((prevState) => ({
                ...prevState,
                projectManagers,
                designers,
                developers,
                qas
            }));
        }
    }

    return (
        <React.Fragment>
            <div className={`col-md-12 row px-8 ${props.view === "website-details" ? "mt-10 px-8-i" : "mt-4-i"}`}>
                <div className="col-md-6 px-3 border-grey-lighter border-right-only">
                    <h5 className="text-left">Website</h5>
                    <div className="border-grey-lighter border-top-only d-flex flex-row align-center justify-between py-2">
                        <label className="m-0">URL</label>
                        <input
                            className="w-60p px-2" placeholder="Enter URL" value={state.url} onChange={(e) => { setState({ ...state, url: e.target.value, urlError: '' }) }}
                        />
                        {state.urlError ? <label className="eror-fields">{state.urlError}</label> : ''}
                    </div>

                    <div className="border-grey-lighter border-top-only d-flex flex-row align-center justify-between py-2">
                        <label className="m-0">Company</label>
                        <Select
                            className="border-0 outline-none w-60p"
                            styles={customStyles}
                            value={state.selectedCompany}
                            onChange={(e) => selectCompany(e)}
                            options={props.companyOptions}
                            placeholder="Select Company"
                            noOptionsMessage={() => null}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                        />
                        {state.companyError ? <label className="eror-fields">{state.companyError}</label> : ''}
                    </div>

                    <div className="border-grey-lighter border-x-0 d-flex flex-row align-center justify-between py-2">
                        <label className="m-0">Primary Contact</label>
                        <Select
                            className="border-0 outline-none w-60p"
                            styles={customStyles}
                            value={state.selectedContact}
                            onChange={(e) => selectContact(e)}
                            options={state.contactOptions}
                            placeholder="Select Contact"
                            noOptionsMessage={() => null}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                        />
                        {state.companyError ? <label className="eror-fields">{state.companyError}</label> : ''}
                    </div>

                    <div className="my-8 website-details__team">
                        <h5 className="text-left">Team</h5>
                        <div className="col-md-12 row">
                            <TeamAssignmentRow
                                view="edit"
                                projectManagers={state.projectManagers}
                                developers={state.developers}
                                qas={state.qas}
                                designers={state.designers}
                                pricing={state.pricing}
                                updateProjectManagers={updateProjectManagers}
                                updateDevelopers={updateDevelopers}
                                updateQas={updateQas}
                                updateDesigners={updateDesigners}
                                removeProjectManager={removeProjectManager}
                                removeDeveloper={removeDeveloper}
                                removeQa={removeQa}
                                removeDesigner={removeDesigner}
                            />


                            {/* <div className="col-md-4 px-0">
                                <div className="flex-row item-baseline">
                                    <div className="flex-item">Project Manager</div>
                                    <div className="flex-item flex-1 ">
                                        <Select
                                            className="form-select ml-1"
                                            styles={customStyles}
                                            // value={state.qaDetails}
                                            value=""
                                            // onChange={(e) => { setState({ ...state, qaDetails: e, qa: e.value, qaError: '' }) }}
                                            onChange={updateProjectManager}
                                            options={assigneeOptions}
                                            placeholder="Select QA"
                                            noOptionsMessage={() => null}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 px-0">
                                <p>Developer</p>
                                <img src="https://picsum.photos/200" />
                            </div>

                            <div className="col-md-3 px-0">
                                <p>Designer</p>
                                <img src="https://picsum.photos/200" />
                            </div>

                            <div className="col-md-2 px-0">
                                <p>QA</p>
                                <img src="https://picsum.photos/200" />
                            </div> */}
                        </div>
                    </div>

                    <div className="border-grey-lighter border-bottom-only d-flex flex-row align-center justify-between py-2 mt-8">
                        <label className="m-0">Pricing Method</label>
                        <Select
                            className="border-0 outline-none w-60p"
                            styles={customStyles}
                            value={state.pricing}
                            onChange={(e) => selectPricing(e)}
                            options={state.pricingMethods}
                            placeholder="Select Method"
                            noOptionsMessage={() => null}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                        />
                        {state.companyError ? <label className="eror-fields">{state.companyError}</label> : ''}
                    </div>

                    <div className="my-8">
                        <h5 className="text-left">Deployment Policy</h5>
                        <div className="border-grey-lighter border-x-0 d-flex flex-row align-center justify-between py-2">
                            <TextareaAutosize
                                placeholder="Enter Text"
                                className="resize-none w-full min-h-24 max-h-24 overflow-y-auto-i border-0 outline-none"
                                value={state.deploymentPolicy}
                                onChange={(e) => {
                                    // setDeploymentPolicy(e.target.value);
                                    setState({
                                        ...state,
                                        deploymentPolicy: e.target.value
                                    })
                                }}
                            ></TextareaAutosize>
                        </div>
                    </div>

                    <div className="my-8">
                        <h5 className="text-left">Domain Host</h5>
                        <div className="border-grey-lighter border-x-0 d-flex flex-row align-center justify-between py-2">
                        <input className="m-0" id='domainValue' onChange={(value)=>updateCredentials(0, value)} value={state?.credentials?.[0].details.hostName} />
                        </div>

                        <div className="custom-control custom-checkbox mt-4-i">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="domainHostInLastpass"
                                checked={state?.credentials?.[0].details.inLastPass}
                                // ???
                                onChange={updateDomainInLastpass}
                            />
                            <label className="custom-control-label" htmlFor="domainHostInLastpass">Credentials in Lastpass</label>
                        </div>
                    </div>

                    <div className="my-8">
                        <h5 className="text-left">DNS Host</h5>
                        <div className="border-grey-lighter border-x-0 d-flex flex-row align-center justify-between py-2">
                            <input className="m-0" id='dnsValue' onChange={(value)=>updateCredentials(1, value)} value={state?.credentials?.[1].details.hostName} />
                        </div>

                        <div className="custom-control custom-checkbox mt-4-i">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="dnsHostInLastpass"
                                checked={state?.credentials?.[1].details.inLastPass}
                                onChange={updateDnsInLastpass}
                            />
                            <label className="custom-control-label" htmlFor="dnsHostInLastpass">Credentials in Lastpass</label>
                        </div>
                    </div>

                    <div className="my-8">
                        <h5 className="text-left">Website Host</h5>
                        <div className="border-grey-lighter border-x-0 d-flex flex-row align-center justify-between py-2">
                            <input className="m-0 w-100" id='hostValue' onChange={(value)=>updateCredentials(2, value)} value={state?.credentials?.[2].details.hostName} />
                        </div>

                        <div className="custom-control custom-checkbox mt-4-i">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="websiteHostInLastpass"
                                checked={state?.credentials?.[2].details.inLastPass}
                                onChange={updateWebsiteInLastpass}
                            />
                            <label className="custom-control-label" htmlFor="websiteHostInLastpass">Credentials in Lastpass</label>
                        </div>
                    </div>
                </div>

                {/* Notes */}
                <div className="col-md-6 pr-0">
                    <h5 className="text-left">Site Notes</h5>

                    <div className="site-notes-container border-grey-lighter border-top-only d-flex flex-row align-center justify-between py-2">
                        <TextareaAutosize
                            placeholder="Enter Text"
                            className="resize-none w-full min-h-full max-h-dvh overflow-y-auto-i border-0 outline-none"
                            value={state.siteNotes}
                            onChange={(e) => {
                                // setSiteNotes(e.target.value);
                                setState({
                                    ...state,
                                    siteNotes: e.target.value
                                })
                            }}
                        ></TextareaAutosize>
                    </div>
                </div>
            </div>

            {/* Footer */}
            <div className="d-flex flex-row justify-content-end border-grey-lighter border-top-only p-8">
                <button onClick={reset} className="btn btn-gray mr-3 h-9 px-16-i" disabled={state.isSubmit}>
                    { state.isSubmit && <i className="bx bx-loader bx-spin font-size-14 align-middle mr-2"></i> }
                    Cancel
                </button>

                <button onClick={submitWebsite} className="btn btn-primary h-9 px-16-i" disabled={state.isSubmit}>
                    { state.isSubmit && <i className="bx bx-loader bx-spin font-size-14 align-middle mr-2"></i> }
                    Save
                </button>
            </div>
        </React.Fragment >
    );
});

export default WebsiteForm;
  